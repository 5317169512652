<template>
  <div class="pb-3 overlay-scroll">
    <form
      class="d-flex justify-content-between flex-column overlay-scroll"
      @submit.prevent="onDonateHandler"
    >
      <div
        v-if="['one-off', 'regular'].includes(enabledPaymentTypes)"
        class="single-payment-type-info"
      >
        {{ t(`amount_chooser.single_type_info.${enabledPaymentTypes}.${paymentTerminology}`) }}
      </div>
      <div v-else class="pt-3 btn-group mx-4" role="group">
        <button
          type="button"
          class="btn w-50 fs-7"
          :class="{
            'btn-primary selected': isOneOffSelected,
            'btn-outline-primary shadow': !isOneOffSelected,
          }"
          :aria-current="isOneOffSelected"
          @click="currentType = 'one-off'"
        >
          {{ t(`amount_chooser.toggle_button.one-off.${paymentTerminology}`) }}
        </button>
        <button
          class="btn shadow w-50 fs-7"
          :class="{
            'btn-primary selected': isRegularSelected,
            'btn-outline-primary shadow': !isRegularSelected,
          }"
          type="button"
          :aria-current="isRegularSelected"
          @click="currentType = 'regular'"
        >
          <IconHeart
            :aria-label="t(`amount_chooser.toggle_button.regular.${paymentTerminology}`)"
            class="icon-heart align-text-top me-1"
            size="16"
          />
          {{ t(`amount_chooser.toggle_button.regular.${paymentTerminology}`) }}
        </button>
      </div>

      <div class="pt-3 scroll-shadows">
        <EquivalenceGrid
          v-if="equivalenceGridOneoff"
          v-show="isOneOffSelected"
          v-model.number="chosenAmount['one-off']"
          :custom-amount="customAmount || 0"
          :grid="equivalenceGridOneoff"
          type="one-off"
          @update:model-value="customAmount = null"
        />
        <AmountGrid
          v-else-if="amountsOneoff.length > 1"
          v-show="isOneOffSelected"
          key="one-off"
          v-model.number="chosenAmount['one-off']"
          type="one-off"
          :amounts="amountsOneoff"
          :popular="popularOneoff"
          class="mx-4"
          @update:model-value="customAmount = null"
        />
        <SingleAmount
          v-else-if="amountsOneoff.length === 1"
          v-show="isOneOffSelected && (customAmount === '' || customAmount === null)"
          :amount="amountsOneoff[0]"
          :selected="isOneOffSelected && chosenAmount['one-off'] === amountsOneoff[0]"
        />

        <EquivalenceGrid
          v-if="equivalenceGridRegular"
          v-show="isRegularSelected"
          v-model.number="chosenAmount['regular']"
          :custom-amount="customAmount || 0"
          :grid="equivalenceGridRegular"
          type="regular"
          @update:model-value="customAmount = null"
        />
        <AmountGrid
          v-else-if="amountsRegular.length > 1"
          v-show="isRegularSelected"
          key="regular"
          v-model.number="chosenAmount['regular']"
          type="regular"
          :amounts="amountsRegular"
          :popular="popularRegular"
          class="mx-4"
          @update:model-value="customAmount = null"
        />
        <SingleAmount
          v-else-if="amountsRegular.length === 1"
          v-show="isRegularSelected && (customAmount === '' || customAmount === null)"
          :amount="amountsRegular[0]"
          :selected="isRegularSelected && chosenAmount['regular'] === amountsRegular[0]"
        />

        <div class="my-2 mx-4">
          <div v-if="activateFreeAmount" class="input-group my-2">
            <span v-if="symbolLeft" class="input-group-text">{{ currencySymbol }}</span>
            <div class="form-floating">
              <input
                id="custom-amount"
                v-model="customAmount"
                type="number"
                :min="minimumAmount"
                :max="maximumAmount"
                class="form-control focus-ring-primary"
                :placeholder="t('amount_chooser.free_amount')"
                :aria-label="t('amount_chooser.free_amount')"
                :required="!amountsOneoff.length && !amountsRegular.length"
                @update:model-value="resetAmountSelection"
              />
              <label for="custom-amount">{{ t('amount_chooser.free_amount') }}</label>
            </div>
            <span v-if="!symbolLeft" class="input-group-text">{{ currencySymbol }}</span>
          </div>

          <div v-if="currentType === 'one-off' && addonFieldsDisplay.length" class="my-3">
            <FormFieldFactory
              v-for="config in addonFieldsDisplay"
              v-bind="omit(config, 'weight')"
              :key="config.name"
              v-model="addons[config.name]"
              :full-model="addons"
            />
          </div>

          <div
            v-if="isWidgetEnabled('taxReduction')"
            class="tax-reduction-widget my-3 px-2 py-3 bg-secondary-transparent rounded-2 text-center text-secondary fs-7 lh-sm"
            :class="{
              'mt-1': !activateFreeAmount,
              'd-none': totalAmount && amountAfterTaxReduction === totalAmount,
            }"
          >
            <i18n-t
              v-if="!totalAmount"
              :keypath="`tax_reduction.no_amount.${campaignTaxReductionType}`"
              tag="span"
              class="tax-reduction-empty"
            >
              <template #percent>{{ firstTaxReductionPercent }}</template>
              <template #link>
                <a href="#" @click.prevent="emit('openTaxReductionModal')">{{
                  t(`tax_reduction.tax_name.${campaignTaxReductionType}`)
                }}</a>
              </template>
            </i18n-t>
            <i18n-t
              v-else
              keypath="tax_reduction.info"
              tag="span"
              class="tax-reduction-with-amount"
            >
              <strong>{{ getFormattedAmount(amountAfterTaxReduction, language, currency) }}</strong>
              <a href="#" @click.prevent="emit('openTaxReductionModal')">{{
                t('tax_reduction.link')
              }}</a>
              <span v-if="widgetHasOption('taxReduction', 'display_limitation_clauses')">
                {{ taxReductionLimit }}
              </span>
            </i18n-t>
          </div>

          <FormFieldFactory
            v-for="config in attachFieldsExtraConfig(
              sortedByWeight(formFieldsDisplay['step-1'] || [], currentType)
            )"
            v-bind="omit(config, 'weight', 'attached_to')"
            :key="config.name"
            v-model="(config.attached_to === 'contact' ? contact : paymentData)[config.name]"
            :full-model="config.attached_to === 'contact' ? contact : paymentData"
          />
        </div>
      </div>

      <div class="mx-4">
        <button
          ref="submitButton"
          type="submit"
          class="btn btn-primary rounded-main w-100 mt-2 mb-3 center d-flex justify-content-center"
        >
          <strong class="font-heading text-uppercase">
            {{ donateButtonLabel || t(`amount_chooser.submit_button.${paymentTerminology}`) }}
          </strong>
          <span v-if="addonCount > 0 && totalAmount > 0" class="ms-2">
            {{ getFormattedAmount(totalAmount, language, currency) }}
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import FormFieldFactory from '@/components/FormFieldFactory.vue'
import IconHeart from '@/components/icons/IconHeart.vue'
import AmountGrid from '@/components/inputs/AmountGrid.vue'
import EquivalenceGrid from '@/components/inputs/EquivalenceGrid.vue'
import SingleAmount from '@/components/inputs/SingleAmount.vue'
import { useConfigStore, useContactStore, useStore, useWidgetsStore } from '@/store'
import type { AddonField, FieldConfig, FormFieldsDisplay, PaymentTerminology } from '@/types'
import { getCurrencySymbol, getFormattedAmount, isCurrencySymbolLeft } from '@/utils/currency'
import { attachFieldsExtraConfig } from '@/utils/form-fields'
import { omit, sortedByWeight } from '@/utils/objects'
import { isElementVisible } from '@/utils/toolbox'

const emit = defineEmits<{
  (e: 'setAmount'): void
  (e: 'openTaxReductionModal'): void
}>()

const { contact } = storeToRefs(useContactStore())
const {
  popularRegular,
  popularOneoff,
  enabledPaymentTypes,
  activateFreeAmount,
  amountsOneoff,
  amountsRegular,
  isGridAmount,
  equivalenceGridOneoff,
  equivalenceGridRegular,
} = useConfigStore()
const {
  isOneOffSelected,
  isRegularSelected,
  addons,
  addonCount,
  baseAmount,
  totalAmount,
  amountAfterTaxReduction,
  originalOneOffAmount,
  firstTaxReductionPercent,
  campaignTaxReductionType,
  currentType,
  customAmount,
  chosenAmount,
  desiredStep,
  paymentData,
} = storeToRefs(useStore())
const { resetAmountSelection } = useStore()
const { t } = useI18n()
const { isWidgetEnabled, widgetHasOption } = useWidgetsStore()

const minimumAmount = computed(
  () => inject('minimumAmount' + (isOneOffSelected.value ? 'Oneoff' : 'Regular')) as number
)
const maximumAmount = computed(
  () => inject('maximumAmount' + (isOneOffSelected.value ? 'Oneoff' : 'Regular')) as number
)

const submitButton = ref<HTMLFormElement>()

// Config injected from backend
const language = inject('language') as string
const currency = inject('currency') as string
const paymentTerminology = inject<PaymentTerminology>('paymentTerminology')
const donateButtonLabel = inject('donateButtonLabel') as string
const organizationCountryCode = inject<string>('organizationCountryCode', '')
const formFieldsDisplay = inject('formFieldsDisplay') as FormFieldsDisplay
const addonFields = inject('addonFields') as AddonField[]

// Reformat addon fields to match fields display
const addonFieldsDisplay = computed<FieldConfig[]>(() => {
  return addonFields
    .map((addonField: AddonField) => {
      const addonFieldDisplay: any = { ...addonField }
      if (addonField.type === 'dropdown' && addonField.choices) {
        // Display amount in every option's label
        addonFieldDisplay.choices = addonField.choices.map(
          ([value, config]: [string, { label: string; amount: number }]) => [
            value,
            `${getFormattedAmount(config.amount, language, currency)} - ${config.label}`,
          ]
        )
      } else if (addonField.type === 'boolean' && addonField.amount) {
        // Display amount in label
        addonFieldDisplay.label = `${getFormattedAmount(addonFieldDisplay.amount, language, currency)} - ${addonFieldDisplay.label}`
      } else if (addonField.type === 'number') {
        addonFieldDisplay.min = 0
        addonFieldDisplay.type = 'amount'
      }
      return omit(addonFieldDisplay, 'tax_reduction_enabled', 'amount') as FieldConfig
    })
    .sort((a: FieldConfig, b: FieldConfig) => a.weight - b.weight)
})

const currencySymbol = getCurrencySymbol(language, currency)
const symbolLeft = isCurrencySymbolLeft(language, currency)

const taxReductionLimit = computed(() => {
  if (organizationCountryCode === 'BE') {
    return t(`tax_reduction.be.limit.${contact.value.is_company ? 'company' : 'individual'}`)
  }

  return t(`tax_reduction.limit.${campaignTaxReductionType.value}`)
})

watch(baseAmount, () => {
  // Make submit button visible if not visible when amount changes
  if (submitButton.value && !isElementVisible(submitButton.value)) {
    submitButton.value.scrollIntoView(false)
  }
})

// Reset original one-off amount if applicable
watch(currentType, () => {
  if (isOneOffSelected.value && originalOneOffAmount.value) {
    if (isGridAmount(originalOneOffAmount.value, 'one-off')) {
      chosenAmount.value['one-off'] = originalOneOffAmount.value
      customAmount.value = null
    } else {
      customAmount.value = originalOneOffAmount.value
    }
    originalOneOffAmount.value = undefined
  }
})

onMounted(() => {
  if (desiredStep.value === 'amount') {
    desiredStep.value = undefined
  } else if (desiredStep.value) {
    onDonateHandler()
  }
})

function onDonateHandler() {
  if (baseAmount.value) {
    emit('setAmount')
  }
  // Reset desired step when wrong amount
  if (!baseAmount.value) {
    desiredStep.value = undefined
  }
}
</script>
